/**
 * Aside menu config
 *
 * To more info about config of menu items go to knowledgebase
 */
import store from "@/core/services/store";

const loadMenuChildren = function(id = 0) {
  return new Promise(resolve => {
    this.$api.lms.categories
      .menu_list(id)
      .then(response => {
        const list = [];
        response.data.data.forEach(cat => {
          list.push(createCategoryOption(cat));
        });
        resolve(list);
      })
      .catch(() => {
        resolve(null);
      });
  });
};
const loadAncestorsTree = function(entity_id) {
  return new Promise((resolve, reject) => {
    if (itemExistsOnMenu.call(this, entity_id)) {
      this.$store.commit("menu/CLOSE_ALL");
      resolve();
    } else {
      this.$api.categories
        .buildAncestorsTree(entity_id)
        .then(response => {
          let base_route = "aside.categories";
          response.data.forEach(level => {
            if (level[0].parent_id) base_route += ".cat" + level[0].parent_id;
            const list = [];

            level.forEach(cat => {
              list.push(createCategoryOption(cat));
            });
            const payload = {
              subMenu: list,
              code: base_route
            };
            this.$store.dispatch("menu/addSubmenu", payload);
          });
          resolve();
        })
        .catch(() => {
          reject(new Error());
        });
    }
  });
};

const itemExistsOnMenu = function(entity_id) {
  const loadedOptions = Object.keys(this.$store.getters["menu/list"]);
  const prefix = "cat";
  const itemLastCode = prefix + entity_id;
  let flag = false;
  loadedOptions.forEach(category => {
    const arr = category.split(".");
    if (arr[arr.length - 1] === itemLastCode) flag = true;
  });
  return flag;
};

const createCategoryOption = category => {
  return {
    type: "link",
    to: {
      name: "category.show",
      params: { id: category.id }
    },
    //icon: "flaticon2-files-and-folders", // not required
    b_icon: "folder",
    active: true,
    text: {
      default: category.title
    },
    code: "cat" + category.id,
    loaded: true
  };
};

export { createCategoryOption, loadAncestorsTree };
export default {
  // Root level also must be configured as menu item (must have submenu key, other ignored)
  submenu: {
/*     my_courses: {
      active: true,
      to: {
        name: "courses.user",
        params: function(storage_getters) {
          return { id: storage_getters.currentUserId };
        }
      },
      text: {
        code: "lms.page_titles.my_courses",
        default: "My courses"
      },
      b_icon: "journal-bookmark-fill"
    }, */
    courses_to_familiarization: {
      active: true,
      to: {
        name: "courses.familiarization",
        params: {}
      },
      text: {
        code: "lms.page_titles.familiarization_courses",
        default: "Familiarization courses"
      },
      b_icon: "exclamation-triangle"
    },
    all_courses: {
      active: true,
      to: {
        name: "courses.all",
        params: {}
      },
      text: {
        code: "lms.page_titles.all_courses",
        default: "All courses"
      },
      b_icon: "journals"
    },
    categories: {
      to: {
        name: "categories.list",
        params: {}
      },
      loader: function() {
        return loadMenuChildren.call(this, 0);
      },
      loaded: false,
      text: {
        code: "lms.page_titles.categories",
        default: "Categories"
      },
      submenu: [],
      b_icon: "folder",
    },
    administration: {
      active: function() {
        const user = store.getters.currentUser;
        return user ? user.is_admin : false;
      },
      text: {
        code: "lms.page_titles.administration",
        default: "Administration"
      },
      b_icon: "shield-fill-check",
      submenu: {
        create_course: {
          active: true,
          to: {
            name: "course.new",
            params: {}
          },
          text: {
            code: "lms.page_titles.course_new",
            default: "Create course"
          },
          b_icon: "journal-plus"
        },
        create_category: {
          active: true,
          to: {
            name: "category.new",
            params: {}
          },
          text: {
            code: "lms.page_titles.category_new",
            default: "Create category"
          },
          b_icon: "folder-plus"
        },
        draft: {
          active: true,
          to: {
            name: "courses.draft",
            params: {}
          },
          text: {
            code: "lms.page_titles.draft",
            default: "Drafts"
          },
          b_icon: "pencil-square"
        },
        archive: {
          active: true,
          to: {
            name: "courses.archive",
            params: {}
          },
          text: {
            code: "lms.page_titles.archive",
            default: "Archives"
          },
          b_icon: "archive"
        },
        statistics: {
          active: true,
          to: {
            name: "courses.statistics",
            params: {}
          },
          text: {
            code: "lms.page_titles.statistics",
            default: "Statistics"
          },
          b_icon: "graph-up"
        },
      }
    },
    help: {
      active: true,
      to: {
          name: "help",
          params: {},
      },
      text: {
        code: "lms.page_titles.help",
        default: 'Help',
      },
      b_icon: "question-circle",
    },
  }
};
