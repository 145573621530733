import courses from "./courses";
import lessons from "./lessons";
import categories from "./categories";
import statistics from "./statistics";
import familiarization from "./familiarization";
import competence from "@hihub/vue-hihub-competences/src/store/competence";


/**
 * Vuex store accumulator
 *
 * Reexport to simplest use in main.js
 */

export default [
  {
    name: "courses",
    module: courses
  },
  {
    name: "lessons",
    module: lessons
  },
  {
    name: "categories",
    module: categories
  },
  {
    name: "statistics",
    module: statistics
  },
  {
    name: "familiarization",
    module: familiarization
  },
  {
    name: "competence",
    module: competence
  }
];
