export default {
  lms: {
    page_titles: {
      main: "Главная",
      my_courses: "Мои курсы",
      all_courses: "Все курсы",
      draft: "Черновики",
      archive: "Архив",
      categories: "Категории",
      administration: "Администратор",
      course_new: "Создать курс",
      category_new: "Создать категорию",
      courses_empty: "Курсов пока еще нет",
      categories_empty: "Категории еще не созданы",
      help: "Помощь",
      statistics: "Статистика прохождения",
      familiarization_courses: "Назначенные курсы"
    },
    excel: {
      download_excel: "Excel",
      fail_export: "Не удалось экспортировать в excel"
    }
  },
  course: {
    tabs: {
      description: "Описание",
      structure: "Структура",
      content: "Содержание",
      lesson: "Урок: {title}",
      module: "Модуль: {title}",
      familiarization: "Назначение обучения"
    },
    description_edit: {
      message: {
        error: {
          title: "",
          text: "Не удалось сохранить изменения"
        }
      }
    },
    create: "Создание курса",
    title: "Название курса",
    title_placeholder: "Введите название курса",
    categories: "Категории",
    categories_placeholder: "Выберите категории",
    category_no_found: "Категория не найдена",
    description: "Описание курса",
    description_placeholder: "Введите описание курса",
    additionally: "Дополнительно",
    additionally_placeholder: "(обложка курса, статус)",
    status_title: "Статус",
    statuses: {
      draft: "Черновик",
      published: "Опубликован",
      archive: "Архив"
    },
    lesson_edit: {
      title: "Название урока",
      content: "Содержание урока",
      message: {
        error: {
          title: "Не удалось сохранить урок",
          text: "Название и содержание урока обязательны для заполнения"
        }
      }
    },
    modal_info: {
      save: "Сохранить изменения?",
      delete_module: "Удалить модуль?",
      delete_lesson: "Удалить урок?"
    },
    button: {
      create: "Создать курс",
      show: "Перейти к просмотру",
      start: "Приступить",
      continue: "Продолжить",
      go_to_course: "Перейти к курсу",
      publish: "Опубликовать",
      back: "Назад",
      next: "Далее",
      links: {
        archive: "Убрать в архив",
        edit: "Редактировать",
        delete: "Удалить"
      },
      answer: "Ответить",
      show_more: "Показать еще",
      continue_training: "Продолжить обучение",
      find_course: "Найти новый курс",
      familiarization_finish: "Подтвердить завершение"
    },
    card: {
      author: "Автор: ",
      categories: "Категории: ",
      added: "Добавлен: ",
      duration: "Время прохождения: ",
      hours: "часов"
    },
    comments: {
      title: "Комментарии"
    },
    structure: {
      lesson: "Урок",
      lesson_statuses: {
        new: "Новый",
        in_progress: "В процессе",
        completed: "Пройден"
      },
      add: {
        module: "добавить модуль",
        lesson: "добавить урок",
        exam: "добавить тест"
      },
      placeholder: {
        module: "Введите название модуля",
        lesson: "Введите название урока"
      },
      validation_error: "Название должно быть длиной от {min} до {max} символов",
      message: {
        error: {
          title: "Не удалось сохранить структуру",
          text: "Один или несколько модулей не имеют названия или уроков"
        },
        template_missing: "Для того, чтобы добавить тест в курс, необходимо сначала создать его и опубликовать в сервисе Тестирование. Без этого действия тест не будет доступен для добавления"
      }
    }
  },
  category: {
    create: "Создание категории",
    title: "Название",
    title_placeholder: "Введите название категории",
    description: "Описание категории",
    description_placeholder: "Введите описание категории",
    no_found: "Категория не найдена",
    name: "Категории",
    courses: "Курсы"
  },
  froala: {
    content_placeholder: "Введите текст здесь",
    text: {
      code: "Код",
      highlighted: "Подсвеченный",
      transparent: "Прозрачный",
    },
    paragraph: {
      grey: "Серый",
      bordered: "Обрамлённый",
      spaced: "С пробелами",
      uppercase: "Заглавный"
    },
    link_styles: {
      green: "Зелёный",
      strong: "Жирный"
    }
  },
  popup: {
    congratulations: "Поздравляем!",
    course: "Курс ",
    completed: " завершен",
    studied: "Вы изучили ",
    materials: " % материалов курса"
  },
  table: {
    search_placeholder: "Начните вводить название курса",
    created_at: "Дата начала",
    responsible: "Ответственный",
    position: "Должность",
    department: "Отдел",
    course_title: "Курс",
    completed_at: "Дата окончания",
    status: "Статус",
    lessons_completed: "Количество пройденных уроков"
  },
  course_statuses: {
    completed: "Завершён",
    in_progress: "В процессе"
  },
  delete_category: {
    modal_title: "Удаление категории",
    modal_text: "Вы уверены, что хотите удалить категорию вместе со всем содержимым?",
  },
};
