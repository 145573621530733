/* eslint-disable */
import Vue from "vue";
import correctDateAccordingWithTimezone from "@hihub/vue-hihub-ui-core/src/core/services/helpers/timezone-fix.js";

const familiarization = {
  namespaced: true,
  state: {
    list: [],
    loading: false,
    loaded: false,
    tasks: {},
    taskListDispatchCounter: 0,
    lastDispatchId: 0,
    familiarizationListPaginationProperties: {
      lastPage: false,
      currentPage: 1
    },
  },
  getters: {
    loaded: (state) => state.loaded,
    loading: (state) => state.loading,
    hasCourses: (state) => {
      return state.list.filter(item => !item.completed).length;
    },
    list: (state) => state.list.filter(item => !item.completed),
    getByCourseId: (state) => (id) => {
      return state.list.find((item) => Number(item.course_id) === Number(id));
    },
    getTasksByCourseId: (state) => (id) => state.tasks[id],
    getTaskListDispatchCounter: (state) => (id) => state.taskListDispatchCounter,
    listPaginationProperties: state => state.familiarizationListPaginationProperties,
  },
  mutations: {
    SET_LOADED(state) {
      state.loaded = true;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_LIST(state, data) {
      Vue.set(state, "list", Object.values(data.data));
      // data.data.forEach(course => {
      //   Vue.set(state.list, course.id, course);
      // });
      // if (Number(data.meta.last_page) === Number(data.meta.current_page)) {
      //   state.listPaginationProperties.lastPage = true;
      // } else {
      //   state.listPaginationProperties.lastPage = false;
      //   state.listPaginationProperties.currentPage++;
      // }
    },
    RESET_LIST(state, data) {
      Vue.set(state, "list", Object.values(data.data));
      // data.data.forEach(course => {
      //   Vue.set(state.list, course.id, course);
      // });
      // if (Number(data.meta.last_page) === Number(data.meta.current_page)) {
      //   state.listPaginationProperties.lastPage = true;
      // } else {
      //   state.listPaginationProperties.lastPage = false;
      //   state.listPaginationProperties.currentPage = 2;
      // }
    },
    UNCLOSE_TASK_IN_LIST(state, data) {
      state.list.forEach(item => {
        if(item.task_id == data.task_id){
          item.completed = false;
        }
      });
    },
    CLOSE_TASK_BY_ID(state, taskId) {
      if (state.list) {
        state.list.forEach(item => {
          if(item.task_id == taskId){
            item.completed = true;
          }
        });
      }
    },
    SET_LOADING_STATUS_FOR_TASK(state, { taskId, status }) {
      state.list.find(
        (item) => Number(item.task_id) === Number(taskId)
      ).loading = status;
    },
    SET_USERS_TASKS(state, data) {
      Vue.set(state.tasks, data.id, {"data": data.data, "pagination": data.pagination});
    },
    UPDATE_TASK_LIST_DISPATCH_COUNTER(state, data){
      let val = Number(data);
      let newVal = state.taskListDispatchCounter + val;
      Vue.set(state, "taskListDispatchCounter", newVal);
    },
  },
  actions: {
    load(context, data) {
      if (context.state.loaded) return;

      // data.page = data.page
      //     ? data.page
      //     : context.state.listPaginationProperties.currentPage;
      let request = this.$app.$api.familiarization.load(data);
      request.then(response => {
        if (data.page > 1) {
          context.commit("SET_LIST", response.data);
        } else {
          context.commit("RESET_LIST", response.data);
        }
        context.commit("SET_LOADED");
      });
      return request;
    },
    getTemplate({commit, state}, {entity_id}){
      const request = this.$app.$api.familiarization.getTemplate(entity_id);
      return request;
    },
    closeTask(context, {task_id}){
      const request = this.$app.$api.familiarization.close(task_id);
      request.then((resp) => {
        context.commit("CLOSE_TASK_BY_ID", task_id);
      });

      return request;
    },
    uncloseTask(context, {task_id}){
      const request = this.$app.$api.familiarization.unclose(task_id);
      request.then((resp) => {
        context.commit("UNCLOSE_TASK_IN_LIST", resp.data);
      });

      return request;
    },
    createTemplate({commit, state}, {entityId, data}){
      const request = this.$app.$api.familiarization.createTemplate(entityId, data);
      return request;
    },
    rerunTask({commit, state}, { template, data }){
      const request = this.$app.$api.familiarization.rerunTasks(template, data);
      return request;
    },
    loadUsersTasksByCourseId({commit, state}, data){
      let sort = [];
      if(data.sort){
        sort.push({ [data.sort.sort_column] : data.sort.sort_type});
      } else {
        sort = [
          {"created_at_table": {"order": "desc"}}
        ];
      }
      let page = data.page || 1;
      let query = data.query || "";
      let filter = data.filter || [];
      commit("UPDATE_TASK_LIST_DISPATCH_COUNTER", 1);
      state.lastDispatchId++;
      let dispatchId = state.lastDispatchId;
      return this.$app.$api.familiarization.load_users_tasks({id: data.entityId, page: page, per_page: 10, search: query, sort: sort, filter: filter})
        .then((response) => {
          if(state.lastDispatchId == dispatchId){
            let pagination = {};
            for(let index in response.data){
                if(index != "data"){
                    pagination[index] = response.data[index];
                }
            }
            let resultData = response.data.data ? response.data.data : {};
            //transform dates according with timezone
            resultData.forEach(row => {
              if(row.hasOwnProperty("completed_at_table")){
                if(row.completed_at_table.value){
                  row.completed_at_table.value = correctDateAccordingWithTimezone(row.completed_at_table.value);
                }
              }
              if(row.hasOwnProperty("additions")){
                if(row.additions.completed_at){
                  row.additions.completed_at = correctDateAccordingWithTimezone(row.additions.completed_at);
                }
              }
            });
            commit("SET_USERS_TASKS", {"id": data.entityId, "data": resultData, "pagination": pagination});
          }
        })
        .finally(() => {
          commit("UPDATE_TASK_LIST_DISPATCH_COUNTER", -1);
        });
    }
  },
};

export default familiarization;
