export default class ApiRequests {}

ApiRequests.lms = {
  courses: {
    index: ({ client }, data) => client.get("/api/lms/courses" + "?page=" + data.page),
    draft: ({ client }) => client.get("/api/lms/courses-draft"),
    archive: ({ client }) => client.get("/api/lms/courses-archive"),
    getById: ({ client }, id) => client.get("/api/lms/course/" + id),
    create: ({ client }, data) => client.post("/api/lms/course", data),
    updateInfo: ({ client }, data) => client.post("/api/lms/course/" + data.id, data),
    updateStructure: ({ client }, data) =>
      client.post("/api/lms/course/" + data.course_id + "/structure/update", data),
    start: ({ client }, id) => client.get("/api/lms/start-course/" + id),
    complete: ({ client }, id) => client.get("/api/lms/complete-course/" + id)
  },
  categories: {
    save: ({ client }, data) => client.post("/api/lms/category", data),
    menu_list: ({ client }, id) => client.get("/api/lms/categories-menu/" + id),
    list: ({ client }) => client.get("/api/lms/categories"),
    getById: ({ client }, id) => client.get("/api/lms/category/" + id),
    deleteById: ({ client }, id) => client.delete("/api/lms/category/" + id)
  },
  lessons: {
    getById: ({ client }, id) => client.get("/api/lms/lesson/" + id),
    updateContent: ({ client }, data) => client.post("/api/lms/lesson/" + data.id, data),
    start: ({ client }, data) => client.post("/api/lms/start-lesson/" + data.id, data),
    complete: ({ client }, id) => client.get("/api/lms/complete-lesson/" + id),
    associate: ({ client }, data) => client.post("/api/lms/lesson/" + data.lessonId + "/" + data.examTemplateId),
  },
  statistics: {
    load: ({ client }, data) => client.post("/api/lms/statistics", data),
    exportStatistics: ({ client }, data) => client.post("/api/lms/statistics/excel", data, {responseType: 'blob'}),
  }
};

ApiRequests.familiarization = {
  createTemplate: ({ client }, id, data) =>
      client.post("/api/lms/familiarization/create-template/" + id, data),
  rerunTasks: ({ client }, id, data) =>
      client.post("/api/lms/familiarization/rerun/" + id, data),
  getTemplate: ({ client }, id) =>
      client.get("/api/lms/familiarization/" + id + "/get-template/"),
  load: ({ client }) => client.get("/api/lms/familiarization/courses-to-familiarize"),
  close: ({ client }, task_id) => client.patch("/api/lms/familiarization/close/" + task_id),
  unclose: ({ client }, task_id) =>
      client.patch("/api/lms/familiarization/unclose/" + task_id),
  load_users_tasks: ({ client }, data) => {
    return client.post("/api/lms/familiarization/load_users_tasks_for_course", data);
  }
};
