import Vue from "vue";

const statistics = {
    namespaced: true,
    state: {
        tableLoadingData: {},
        list: {},
        listDispatchCounter: 0,
        lastListDispatchId: 0,
    },
    getters: {
        getListDispatchCounter: state => () => state.listDispatchCounter,
        getList: state => () => state.list,    
    },
    mutations: {
        SET_TABLE_LOADING_DATA(state, data){
            let sort = [];
            if(data.sort){
                sort.push({ [data.sort.sort_column] : data.sort.sort_type});
            } else {
                sort = [
                {"created_at_table": {"order": "desc"}}
                ];
            }
            let page = data.page || 1;
            let query = data.query || "";
            let filter = data.filter || [];
        
            let obj = {
                sort: sort,
                page: page,
                query: query,
                filter: filter
            };
            Vue.set(state, "tableLoadingData", obj);
        },
        SET_LIST(state, {data, pagination}){
            Vue.set(state.list, "data", data);
            Vue.set(state.list, "pagination", pagination);
        },
        UPDATE_LIST_DISPATCH_COUNTER(state, data){
            let val = Number(data);
            let newVal = state.listDispatchCounter + val;
            Vue.set(state, "listDispatchCounter", newVal);
        },      
    },
    actions: {
        loadStatistics({state, commit}, data){
            commit("SET_TABLE_LOADING_DATA", data);
            commit("UPDATE_LIST_DISPATCH_COUNTER", 1);
            state.lastListDispatchId++;
            let dispatchId = state.lastListDispatchId;
            return this.$app.$api.lms.statistics.load({
              page: state.tableLoadingData.page, 
              per_page: 10, 
              search: state.tableLoadingData.query, 
              sort: state.tableLoadingData.sort, 
              filter: state.tableLoadingData.filter
            }).then((response) => {
              if(state.lastListDispatchId == dispatchId){
                let pagination = {};
                for(let index in response.data){
                  if(index != "data"){
                    pagination[index] = response.data[index];
                  }
                }
                commit("SET_LIST", {"data": response.data.data, "pagination": pagination});
              }
            })
            .finally(() => {
              commit("UPDATE_LIST_DISPATCH_COUNTER", -1);
            });
        },
        exportToExcelStatistics(context, data) {
          return new Promise((resolve, reject) => {
              this.$app.$api.lms.statistics.exportStatistics(data)
                  .then(response => {
                      resolve(response);
                  })
                  .catch(error => {
                      reject(error);
                  });
          });
      },
    }
  };
  
  export default statistics;