import Vue from "vue";

const lessons = {
  namespaced: true,
  state: {
    listLessons: {
      // lesson_id_1: { lesson_object_1 },
      // lesson_id_2: { lesson_object_2 },
    }
  },
  getters: {
    getLessonById: state => lesson_id => state.listLessons[lesson_id]
  },
  mutations: {
    ADD_LESSON_TO_LIST(state, lesson) {
      Vue.set(state.listLessons, lesson.id, lesson);
    },
    SET_LESSON_USER_STATUS(state, { lesson_id, user_status }) {
      Vue.set(state.listLessons[lesson_id], "user_status", user_status);
    }
  },

  actions: {
    loadLesson(context, { id, fix_progress = false, lesson_to_complete = null }) {
      let request = "";
      if (fix_progress) {
        request = this.$app.$api.lms.lessons.start({
          id: id,
          lesson_to_complete: lesson_to_complete
        });
      } else {
        request = this.$app.$api.lms.lessons.getById(id);
      }
      request
        .then(response => {
          const lesson = response.data.data;
          if (lesson.lessonable_type === 'examtemplate') lesson['form'] = null;
          context.commit("ADD_LESSON_TO_LIST", lesson);
          if (fix_progress) {
            context.commit("courses/UPDATE_LESSON_IN_MODULE", lesson, { root: true });

            /* Mark previous lesson as completed */
            if (lesson_to_complete) {
              context.commit("SET_LESSON_USER_STATUS", { lesson_id: lesson_to_complete, user_status: 2 });
              let updated_lesson = context.state.listLessons[lesson_to_complete];
              context.commit("courses/UPDATE_LESSON_IN_MODULE", updated_lesson, { root: true });
            }
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });

      return request;
    },
    updateLessonContent(context, lesson) {
      let request = this.$app.$api.lms.lessons.updateContent(lesson);
      request
        .then(response => {
          context.commit("ADD_LESSON_TO_LIST", response.data.data);
          context.commit("courses/UPDATE_LESSON_IN_MODULE", response.data.data, { root: true });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      return request;
    },
    completeLesson(context, lesson_id) {
      let request = this.$app.$api.lms.lessons.complete(lesson_id);
      request.then(() => {
        context.commit("SET_LESSON_USER_STATUS", { lesson_id: lesson_id, user_status: 2 });
        let updated_lesson = context.state.listLessons[lesson_id];
        context.commit("courses/UPDATE_LESSON_IN_MODULE", updated_lesson, { root: true });
      });
      return request;
    }
  }
};

export default lessons;
