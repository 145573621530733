import Vue from "vue";

const categories = {
  namespaced: true,
  state: {
    listCategories: {
      // category_id_1: { category_object_1 },
      // category_id_2: { category_object_2 },
    },
    listLoaded: false
  },
  getters: {
    listOfCategories: state => state.listCategories,
    listOfCategoriesLoaded: state => state.listLoaded,
    getCategoryById: state => category_id => state.listCategories[category_id]
  },
  mutations: {
    ADD_CATEGORY_TO_LIST(state, category) {
      Vue.set(state.listCategories, category.id, category);
    },
    SET_CATEGORIES_LIST_LOADED(state, loaded) {
      Vue.set(state, "listLoaded", loaded);
    },
    REMOVE_FROM_LIST(state, id) {
      Vue.delete(state.listCategories, id);
    }
  },

  actions: {
    loadCategoriesList(context) {
      context.commit("SET_CATEGORIES_LIST_LOADED", false);
      let request = this.$app.$api.lms.categories.list();
      request.then(response => {
        Object.values(response.data.data).forEach(category => {
          context.commit("ADD_CATEGORY_TO_LIST", category);
        });
        context.commit("SET_CATEGORIES_LIST_LOADED", true);
      });
      return request;
    },
    saveCategory(context, category) {
      let request = this.$app.$api.lms.categories.save(category);
      request.then(response => {
        context.commit("ADD_CATEGORY_TO_LIST", response.data.data);
      });
      return request;
    },
    loadCategoryFull(context, id) {
      let request = this.$app.$api.lms.categories.getById(id);
      request.then(response => {
        context.commit("ADD_CATEGORY_TO_LIST", response.data);
      });
      return request;
    },
    deleteCategory({commit}, id) {
      return this.$app.$api.lms.categories.deleteById(id).then(() => {
        commit("REMOVE_FROM_LIST", id);
      });
    }
    /*    loadCategories(context) {
      context.commit("SET_CATEGORIES_LIST_LOADED", false);
      let request = this.$app.$api.lms.categories.menu_list(0);
      request.then(response => {
        Object.values(response.data.data).forEach(category => {
          context.commit("ADD_CATEGORY_TO_LIST", category);
        });
        
      });
      return request;
    }*/
  }
};

export default categories;
