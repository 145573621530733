import Vue from "vue";

const courses = {
  namespaced: true,
  state: {
    listCourses: {
      // course_id_1: { course_object_1 },
      // course_id_1: { course_object_2 },
      //     modules: {
      //       module_order_1: { course_object_1 },
      //       module_order_2: { course_object_2 },
      //     }
    },
    listLoaded: false,
    listPaginationProperties: {
      lastPage: false,
      currentPage: 1
    },
    draftCourses: {},
    archiveCourses: {}
  },
  getters: {
    listPaginationProperties: state => state.listPaginationProperties,
    listOfCourses(state) {
      return state.listCourses;
    },
    listOfDrafts(state) {
      return state.draftCourses;
    },
    listOfArchives(state) {
      return state.archiveCourses;
    },
    getListOfCoursesLoaded(state) {
      return state.listLoaded;
    },
    getCourseById: state => course_id => state.listCourses[course_id],
    getAllLessons: state => course_id => {
      let course = state.listCourses[course_id];
      let lessons = [];
      if(course){
        let modules = course.modules;
        Object.keys(modules).forEach((key) => {
          let lessonsArr = modules[key].lessons;
          lessons = lessons.concat(lessonsArr);
        });
      }
      return lessons;
    },
    getCompletedLessons: state => course_id => {
      let course = state.listCourses[course_id];
      let lessons = [];
      if(course){
        let modules = course.modules;
        Object.keys(modules).forEach((key) => {
          let lessonsArr = modules[key].lessons;
          lessonsArr.forEach(lesson => {
            if(lesson.user_status == 2) {
              lessons.push(lesson);
            }
          });
        });
      }
      return lessons;
    },
    getLessonInProgress: state => course_id => {
      let lesson_in_progress_id = null;
      let lesson_new_id = null;

      let modules = Object.values(state.listCourses[course_id].modules);
      for (let i = 0; !lesson_in_progress_id && i < modules.length; i++) {
        let module = modules[i];

        for (let i = 0; i < module.lessons.length; i++) {
          let lesson = module.lessons[i];
          if (lesson.user_status === 1) {
            lesson_in_progress_id = lesson.id;
            break;
          } else if (!lesson.user_status && !lesson_new_id) {
            lesson_new_id = lesson.id;
          }
        }
      }
      return lesson_in_progress_id ?? lesson_new_id; //  if both null, course is completed
    },

    getPreviousLessonId: state => (course_id, module_order, lesson_order) => {
      let modules = state.listCourses[course_id].modules;
      if (lesson_order === 1) {
        /* Lesson is first in his module */
        if (module_order === 1) {
          return null;
        } else {
          /* Get last lesson from previous module */
          let prev_module_lessons = modules[module_order - 1].lessons;
          return prev_module_lessons[prev_module_lessons.length - 1].id;
        }
      } else {
        /* Get previous lesson from current module */
        return modules[module_order].lessons.find(lesson => lesson.order === (lesson_order - 1)).id;
      }
    },

    getNextLessonId: state => (course_id, module_order, lesson_order) => {
      let current_module = state.listCourses[course_id].modules[module_order];

      if (current_module.lessons.length > lesson_order)
        return current_module.lessons.find(lesson => lesson.order === (lesson_order + 1)).id;
/*       let indexLessonArray, subArray, generalArrayLessons, searchDuplicates;
      if (current_module.lessons.length > lesson_order) {
        if (Object.keys(state.listCourses[course_id].modules).length === 1) {
          indexLessonArray = (current_module.lessons.indexOf(current_module.lessons.find(lesson => lesson.order === lesson_order ))) + 1;
          subArray = current_module.lessons.slice(indexLessonArray);
          searchDuplicates = subArray.filter((number) => {
            return number.user_status != 2;
          });
          if( searchDuplicates.length === 0 ) {
            return null;
          }
          else {
            return subArray.find(lesson => lesson.user_status != 2).id;
          }
        }
        else {
          indexLessonArray = (current_module.lessons.indexOf(current_module.lessons.find(lesson => lesson.order === lesson_order ))) + 1;
          subArray = current_module.lessons.slice(indexLessonArray);
          Object.values(state.listCourses[course_id].modules).forEach(element => {
            generalArrayLessons = [...subArray, ...element.lessons];
          });
          searchDuplicates = generalArrayLessons.filter((number) => {
            return number.user_status != 2;
          });
          if( searchDuplicates.length === 1 ) {
            return null;
          }
          else {
            let idLesson = generalArrayLessons.find(lesson => lesson.user_status != 2).id;
            return idLesson;
          }
        }
      } */
      else if (state.listCourses[course_id].modules[module_order + 1]) {
        return state.listCourses[course_id].modules[module_order + 1].lessons[0].id;
      } else {
        return null;
      }
    }
  },
  mutations: {
    ADD_COURSE_TO_LIST(state, course) {
      Vue.set(state.listCourses, course.id, course);

      let modulesObject = {};
      course.modules.forEach(module => {
        modulesObject[module.order] = module;
      });
      Vue.set(state.listCourses[course.id], "modules", modulesObject);
    },
    SET_COURSES_LIST_LOADED(state, loaded) {
      Vue.set(state, "listLoaded", loaded);
    },
    ADD_MODULE_TO_COURSE(state, { course_id, module }) {
      Vue.set(state.listCourses[course_id]["modules"], module.order, module);
    },
    ADD_LESSON_TO_MODULE(state, { course_id, module_order, type }) {
      let lesson_order = state.listCourses[course_id].modules[module_order].lessons.length + 1;
      let empty_lesson = { id: null, title: null, order: lesson_order, lessonable_type: type, module_order: module_order };
      //Vue.set(state.listCourses[course.id], modules, )
      state.listCourses[course_id].modules[module_order].lessons.push(empty_lesson);
    },
    DELETE_LESSON_FROM_MODULE(state, { course_id, module_order, lesson_order }) {
      let lessons = state.listCourses[course_id].modules[module_order].lessons.sort(
          (a, b) => a.order > b.order ? 1 : -1
      );
      lessons.splice(lesson_order - 1, 1);
      lessons.forEach((lesson) => {
        if (lesson.order > lesson_order)
          lesson.order--;
      });
    },
    DELETE_MODULE_FROM_COURSE(state, { course_id, module_order }) {
      let modules = state.listCourses[course_id].modules;
      let length = Object.keys(modules).length;
      Vue.delete(modules, module_order);
      for (let order_key = 0; order_key <= length; order_key++) {
        if (order_key > module_order) {
          modules[order_key].order--;
          modules[order_key - 1] = modules[order_key];
          if (order_key === length)
            Vue.delete(modules, order_key);
        }
      }
    },
    UPDATE_LESSON_IN_MODULE(state, updated_lesson) {
      let module = state.listCourses[updated_lesson.course_id].modules[updated_lesson.module_order];
      let lesson = module.lessons.find(lesson => lesson.id === updated_lesson.id);
      Object.keys(lesson).forEach(key => {
        Vue.set(lesson, key, updated_lesson[key]);
      });
    },
    SET_COURSE_USER_STATUS(state, { course_id, user_status }) {
      Vue.set(state.listCourses[course_id], "user_status", user_status);
    },
    ADD_TO_COURSES_LIST(state, data) {
      data.data.forEach(course => {
        Vue.set(state.listCourses, course.id, course);
      });
       if (Number(data.meta.last_page) === Number(data.meta.current_page)) {
        state.listPaginationProperties.lastPage = true;
      } else {
        state.listPaginationProperties.lastPage = false;
        state.listPaginationProperties.currentPage++;
      }
    },
    OVERWRITE_COURSES_LIST(state, data) {
      data.data.forEach(course => {
        Vue.set(state.listCourses, course.id, course);
      });
      if (Number(data.meta.last_page) === Number(data.meta.current_page)) {
        state.listPaginationProperties.lastPage = true;
      } else {
        state.listPaginationProperties.lastPage = false;
        state.listPaginationProperties.currentPage = 2;
      }
    },
    ADD_UNPUBLISH_COURSES(state, courses) {
      if (courses[0].status === 1) {
        courses.forEach(course => {
          Vue.set(state.draftCourses, course.id, course);
        });
      } else {
        courses.forEach(course => {
          Vue.set(state.archiveCourses, course.id, course);
        });
      }
    }
  },

  actions: {
    loadCoursesList(context, data) {
      context.commit("SET_COURSES_LIST_LOADED", false);
      data.page = data.page
        ? data.page
        : context.state.listPaginationProperties.currentPage;
      let request = this.$app.$api.lms.courses.index(data);
      request.then(response => {
        if (data.page > 1) {
          context.commit("ADD_TO_COURSES_LIST", response.data);
        } else {
          context.commit("OVERWRITE_COURSES_LIST", response.data);
        }
        context.commit("SET_COURSES_LIST_LOADED", true);
      });
      return request;
    },
    loadCourse(context, id) {
      let request = this.$app.$api.lms.courses.getById(id);
      request.then(response => {
        context.commit("ADD_COURSE_TO_LIST", response.data.data);
      });
      return request;
    },
    createCourse(context, course) {
      let request = this.$app.$api.lms.courses.create(course);
      request.then(response => {
        context.commit("ADD_COURSE_TO_LIST", response.data.data);
      });
      return request;
    },
    updateCourseInfo(context, course) {
      let request = this.$app.$api.lms.courses.updateInfo(course);
      request.then(response => {
        context.commit("ADD_COURSE_TO_LIST", response.data.data);
      });
      return request;
    },
    updateCourseStructure(context, data) {
      let request = this.$app.$api.lms.courses.updateStructure(data);
      request.then(response => {
        Object.values(response.data.data.modules).forEach(module => {
          let examTypeLessons = data.modules[module.order].lessons
              .filter(lesson => lesson.lessonable_type === 'examtemplate');

          module.lessons
              .filter(lesson => !!examTypeLessons.find(examTypeLesson => examTypeLesson.order === lesson.order))
              .forEach(lesson => {
                context.dispatch("associateExamTemplateToLesson", {
                  lessonId: lesson.id,
                  examTemplateId: examTypeLessons.find(examTypeLesson => examTypeLesson.order === lesson.order).lessonable_id
                })
                    .then(() => {
                      lesson.lessonable_id = examTypeLessons.find(examTypeLesson => examTypeLesson.order === lesson.order).lessonable_id;
                      lesson.lessonable_type = 'examtemplate';
                    });
              });

          context.commit("ADD_MODULE_TO_COURSE", {
            course_id: data.course_id,
            module: module
          });
        });
      });
      return request;
    },
    associateExamTemplateToLesson(context, data) {
      this.$app.$api.lms.lessons.associate(data);
    },
    startCourse(context, course_id) {
      let request = this.$app.$api.lms.courses.start(course_id);
      request.then(() => {
        context.commit("SET_COURSE_USER_STATUS", {
          course_id: course_id,
          user_status: 1
        });
      });
      return request;
    },
    completeCourse(context, course_id) {
      let request = this.$app.$api.lms.courses.complete(course_id);
      request.then(() => {
        context.commit("SET_COURSE_USER_STATUS", {
          course_id: course_id,
          user_status: 2
        });
      });
      return request;
    },
    loadUnpublishedCourses(context, status) {
      let request = status === "draft"
          ? this.$app.$api.lms.courses.draft()
          : this.$app.$api.lms.courses.archive();
      request.then(response => {
        if (status === "draft")
          Vue.set(context.state, "draftCourses", {});
        else
          Vue.set(context.state, "archiveCourses", {});
        context.commit("ADD_UNPUBLISH_COURSES", response.data.data);
      });
      return request;
    }
  }
};

export default courses;
