export default {
  lms: {
    page_titles: {
      main: "Hauptseite",
      my_courses: "Meine Kurse",
      all_courses: "Alle Kurse",
      draft: "Entwürfe",
      archive: "Archiv",
      categories: "Kategorien",
      administration: "Administration",
      course_new: "Kurs hinzufügen",
      category_new: "Kategorie hinzufügen",
      courses_empty: "Es gibt noch keine Kurse",
      categories_empty: "Es wurden noch keine Kategorien erstellt",
      help: "Hilfe",
      statistics: "Statistiken"
    },
    excel: {
      download_excel: "Excel",
      fail_export: "Fail export to excel",
    }
  },
  course: {
    tabs: {
      description: "Beschreibung",
      structure: "Struktur",
      content: "Inhalt",
      lesson: "Lektion: {title}",
      module: "Modul: {title}"
    },
    description_edit: {
      message: {
        error: {
          title: "",
          text: "Fehler beim Speichern"
        }
      }
    },
    create: "Kurs hinzufügen",
    title: "Kurstitel",
    title_placeholder: "Geben Sie einen Kurstitel ein",
    categories: "Kategorien",
    categories_placeholder: "Wählen Sie Kategorien aus",
    category_no_found: "Kategorie nicht gefunden",
    description: "Kursbeschreibung",
    description_placeholder: "Geben Sie eine Kursbeschreibung ein",
    additionally: "Zusätzlich",
    additionally_placeholder: "(Kursbild, status)",
    status_title: "Status",
    statuses: {
      draft: "Entwürfe",
      published: "Veröffentlicht",
      archive: "Archiv"
    },
    lesson_edit: {
      title: "Lektionstitel",
      content: "Lektionsinhalt",
      message: {
        error: {
          title: "Fehler beim Speichern",
          text: "Lektionstitel und Lektionsinhalt sind erforderlich"
        }
      }
    },
    modal_info: {
      save: "Änderungen speichern?",
      delete_module: "Löschen ein Modul?",
      delete_lesson: "Löschen eine Lektion?"
    },
    button: {
      create: "Kurs hinzufügen",
      show: "Kurs ansehen",
      start: "Kurs starten",
      continue: "Kurs fortsetzen",
      go_to_course: "Kurs ansehen",
      publish: "Veröffentlichen",
      back: "Zurück",
      next: "Weiter",
      links: {
        archive: "Kurs archivieren",
        edit: "Kurs bearbeiten",
        delete: "Kurs löschen"
      },
      continue_training: "Continue training",
      find_course: "Einen neuen Kurs finden"
    },
    card: {
      author: "Autor: ",
      categories: "Kategorien: ",
      added: "Hinzugefügt: ",
      duration: "Durchlaufzeit: ",
      hours: "stunden"
    },
    structure: {
      lesson: "Lektion",
      lesson_statuses: {
        new: "Neue",
        in_progress: "Im Gange",
        completed: "Vollendet"
      },
      add: {
        module: "Modul hinzufügen",
        lesson: "Lektion hinzufügen",
        exam: "Prüfung hinzufügen"
      },
      placeholder: {
        module: "Geben Sie einen Modultitel ein",
        lesson: "Geben Sie einen Lektionstitel ein"
      },
      validation_error: "Titel muss zwischen 2 und 150 Zeichen lang sein",
      message: {
        error: {
          title: "Fehler beim Speichern",
          text: "Es gibt die Moduln mit keinem Titel oder keinen Lektionen"
        },
        template_missing: "Um eine Prüfung zu einem Kurs hinzuzufügen, müssen Sie sie zuerst erstellen und im Testservice veröffentlichen. Ohne diese Aktion steht die Prüfung nicht zur Ergänzung zur Verfügung"
      }
    }
  },
  category: {
    create: "Erstellen einer Kategorie",
    title: "Kategorietitel",
    title_placeholder: "Geben Sie einen Kategorietitel ein",
    description: "Beschreibung",
    description_placeholder: "Geben Sie eine Kategoriebeschreibung ein",
    no_found: "Kategorie nicht gefunden",
    name: "Kategorien",
    courses: "Kurse"
  },
  froala: {
    content_placeholder: "Geben Sie hier einen Text ein",
    text: {
      code: "Code",
      highlighted: "Beleuchtet",
      transparent: "Transparent"
    },
    paragraph: {
      grey: "Grau",
      bordered: "Umrandet",
      spaced: "Abstand",
      uppercase: "Großbuchstaben"
    },
    link_styles: {
      green: "Grün",
      strong: "Fett"
    }
  },
  popup: {
    congratulations: "Gratulierter!",
    course: "Kurs ",
    completed: " ist abgeschlossen",
    studied: "Haben Sie gelernt ",
    materials: " % kursmaterialien"
  },
  table: {
    search_placeholder: "Beginnen Sie mit der Eingabe Ihres Kursnamens",
    created_at: "Startdatum",
    responsible: "Verantwortlich",
    position: "Positionen",
    department: "Abteilung",
    course_title: "Kurs",
    completed_at: "Abgeschlossen um",
    status: "Status",
    lessons_completed: "Anzahl abgeschlossener Lektionen"
  },
  course_statuses: {
    completed: "Vollendet",
    in_progress: "Im Gange"
  },
  delete_category: {
    modal_title: "Kategorie löschen",
    modal_text: "Sind Sie sicher, dass Sie die Kategorie mit ihrem gesamten Inhalt löschen möchten?",
  }
};
